import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Stack,
  Box,
  FormLabel,
  Input,
  Checkbox,
  useDisclosure,
  Select,
  useToast,
  InputGroup,
  InputRightElement,
  IconButton,
} from "@chakra-ui/react";
import { EditIcon, SearchIcon } from "@chakra-ui/icons";
import { useRef, useState, useEffect } from "react";
import axios from "axios";
import { showToast } from "../toast-alert";

function EditItemDrawer({ dataType, item, onUpdate }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const firstField = useRef();
  const toast = useToast();

  const [formData, setFormData] = useState({ ...item });
  const [contacts, setContacts] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [contactsPerPage] = useState(10);
  const userRole = localStorage.getItem("role");
  const userId = localStorage.getItem("id");

  useEffect(() => {
    if (dataType === "groups") {
      axios.get(`https://api.pawarta.awandigital.id/api/contacts?user_id=${userId}`).then((response) => {
        setContacts(response.data.data);
      });

      axios.get(`https://api.pawarta.awandigital.id/api/groups/${item.id}/contacts`).then((response) => {
        setSelectedContacts(response.data.data.map(contact => contact.id));
      });
    }
  }, [dataType, item.id]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleContactChange = (contactId) => {
    setSelectedContacts((prevSelected) =>
      prevSelected.includes(contactId)
        ? prevSelected.filter((id) => id !== contactId)
        : [...prevSelected, contactId]
    );
  };

  const handleSubmit = () => {
    if (dataType === "groups") {
      axios.put(`https://api.pawarta.awandigital.id/api/groups/${item.id}/contacts`, {
        group_name: formData.group_name,
        contact_id: selectedContacts
      });
    }

    onUpdate(formData);
    onClose();
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const filteredContacts = contacts.filter(contact =>
    contact.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const indexOfLastContact = currentPage * contactsPerPage;
  const indexOfFirstContact = indexOfLastContact - contactsPerPage;
  const currentContacts = filteredContacts.slice(indexOfFirstContact, indexOfLastContact);

  const renderInputFields = () => {
    if (dataType === "contacts") {
      return (
        <>
          <Box>
            <FormLabel htmlFor="name">Name</FormLabel>
            <Input
              ref={firstField}
              id="name"
              value={formData.name}
              onChange={handleChange}
            />
          </Box>

          <Box>
            <FormLabel htmlFor="phone">Phone</FormLabel>
            <Input
              id="phone"
              value={formData.phone}
              onChange={handleChange}
            />
          </Box>

          <Box>
            <FormLabel htmlFor="email">Email</FormLabel>
            <Input
              id="email"
              value={formData.email}
              onChange={handleChange}
            />
          </Box>
        </>
      );
    } else if (dataType === "users") {
      return (
        <>
          <Box>
            <FormLabel htmlFor="firstname">Firstname</FormLabel>
            <Input
              ref={firstField}
              id="firstname"
              value={formData.firstname}
              onChange={handleChange}
            />
          </Box>

          <Box>
            <FormLabel htmlFor="lastname">Lastname</FormLabel>
            <Input
              id="lastname"
              value={formData.lastname}
              onChange={handleChange}
            />
          </Box>

          <Box>
            <FormLabel htmlFor="telephone">Telephone</FormLabel>
            <Input
              id="telephone"
              value={formData.telephone}
              onChange={handleChange}
            />
          </Box>

          <Box>
            <FormLabel htmlFor="email">Email</FormLabel>
            <Input
              id="email"
              value={formData.email}
              onChange={handleChange} />
          </Box>

          {userRole !== "user" && (
            <Box>
              <FormLabel htmlFor="role">Access Role</FormLabel>
              <Select
                id="role"
                value={formData.role}
                onChange={handleChange}
              >
                <option value="user">user</option>
                <option value="superadmin">superadmin</option>
              </Select>
            </Box>
          )}
        </>
      );
    } else if (dataType === "groups") {
      return (
        <>
          <Box>
            <FormLabel htmlFor="group_name">Group Name</FormLabel>
            <Input
              id="group_name"
              value={formData.group_name}
              onChange={handleChange}
            />
          </Box>

          <Box>
            <FormLabel htmlFor="contacts">Contacts</FormLabel>
            <InputGroup mb={3}>
              <Input
                placeholder="Search Contacts"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <InputRightElement>
                <IconButton
                  aria-label="Search"
                  icon={<SearchIcon />}
                  onClick={() => {}}
                />
              </InputRightElement>
            </InputGroup>
            <Stack>
              {currentContacts.map(contact => (
                <Checkbox
                  key={contact.id}
                  isChecked={selectedContacts.includes(contact.id)}
                  onChange={() => handleContactChange(contact.id)}
                >
                  {contact.name}
                </Checkbox>
              ))}
            </Stack>
            <Stack direction="row" mt={3}>
              {[...Array(Math.ceil(filteredContacts.length / contactsPerPage)).keys()].map(num => (
                <Button key={num} onClick={() => handlePageChange(num + 1)}>
                  {num + 1}
                </Button>
              ))}
            </Stack>
          </Box>
        </>
      );
    }
  };

  return (
    <>
      <IconButton 
      icon={<EditIcon/>}
      onClick={onOpen}
        bg=""
        _hover={{ bg: "green.500", color: "white" }}
        ml={1}
        aria-label="Edit Users"/>
      <Drawer
        isOpen={isOpen}
        placement="right"
        initialFocusRef={firstField}
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Edit {dataType.slice(0, -1)}</DrawerHeader>

          <DrawerBody>
            <Stack spacing="24px">{renderInputFields()}</Stack>
          </DrawerBody>

          <DrawerFooter borderTopWidth="1px">
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="green" onClick={handleSubmit}>
              Save
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default EditItemDrawer;
