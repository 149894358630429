import React, { useState, useEffect } from 'react';
import {
  Button, Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent,
  DrawerCloseButton, useDisclosure, Checkbox, VStack, useToast, IconButton, Select, Input, Box, FormControl, FormLabel, SimpleGrid
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const EditLicenseDrawer = ({ item, onUpdate }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [license, setLicense] = useState(item.license || "trial");
  const [licensePeriod, setLicensePeriod] = useState(item.license_period || "");
  const [waMessageLimit, setWaMessageLimit] = useState(item.wa_message_limit || 0);
  const [emailMessageLimit, setEmailMessageLimit] = useState(item.email_message_limit || 0);
  const [access, setAccess] = useState({
    send_message_wa: false,
    api_wa: false,
    send_message_email: false,
    api_email_otp: false
  });
  const userRole = localStorage.getItem("role");
  const toast = useToast();
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    if (item.access) {
      // Assuming item.access is a JSON string, we need to parse it
      let accessArray;
      try {
        accessArray = JSON.parse(item.access);
      } catch (e) {
        console.error("Failed to parse access data:", e);
        return;
      }
      const accessObject = {
        send_message_wa: accessArray.includes('send_message_wa'),
        api_wa: accessArray.includes('api_wa'),
        send_message_email: accessArray.includes('send_message_email'),
        api_email_otp: accessArray.includes('api_email_otp')
      };
      setAccess(accessObject);
    }
  }, [item]);

  const handleSave = () => {
    const accessArray = [];
    if (access.send_message_wa) accessArray.push('send_message_wa');
    if (access.api_wa) accessArray.push('api_wa');
    if (access.send_message_email) accessArray.push('send_message_email');
    if (access.api_email_otp) accessArray.push('api_email_otp');

    const updatedItem = { 
      ...item, 
      license, 
      license_period: licensePeriod, 
      wa_message_limit: waMessageLimit, 
      email_message_limit: emailMessageLimit, 
      access: accessArray 
    };

    console.log("Updated item being sent:", updatedItem);

    axios.put(`https://api.pawarta.awandigital.id/api/users/${item.id}`, updatedItem)
      .then(response => {
        onUpdate(response.data.data);
        toast({
          title: "License updated.",
          description: "User license has been updated successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        onClose();
        navigate(0); // Refresh the page
      })
      .catch(error => {
        console.error("Error updating license:", error);
      });
  };

  if (userRole !== "superadmin") {
    return null;
  }

  return (
    <>
      <IconButton
        icon={<EditIcon />}
        onClick={onOpen}
        bg=""
        _hover={{ bg: "blue.500", color: "white" }}
        ml={1}
        aria-label="Edit License"
      />
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Edit License</DrawerHeader>
          <DrawerBody>
            <VStack align="start" spacing={4}>

              {/* License Configuration */}
              <Box borderWidth="1px" borderRadius="lg" p={4} w="100%">
                <Box fontWeight="bold" mb={2}>License Configuration</Box>
                <FormControl>
                  <FormLabel>Type of License</FormLabel>
                  <Select
                    value={license}
                    onChange={(e) => setLicense(e.target.value)}
                  >
                    <option value="trial">Trial</option>
                    <option value="premium">Premium</option>
                  </Select>
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>License Period</FormLabel>
                  <Input
                    type="date"
                    value={licensePeriod}
                    onChange={(e) => setLicensePeriod(e.target.value)}
                    isDisabled={license === "trial"}
                  />
                </FormControl>
              </Box>

              {/* Access Configuration */}
              <Box borderWidth="1px" borderRadius="lg" p={4} w="100%">
                <Box fontWeight="bold" mb={2}>Access Configuration</Box>
                <SimpleGrid columns={2} spacing={4}>
                  <Checkbox
                    isChecked={access.send_message_wa}
                    onChange={(e) => setAccess({ ...access, send_message_wa: e.target.checked })}
                    isDisabled={license !== "premium"}
                  >
                    Access to Send Message WA
                  </Checkbox>
                  <Checkbox
                    isChecked={access.api_wa}
                    onChange={(e) => setAccess({ ...access, api_wa: e.target.checked })}
                    isDisabled={license !== "premium"}
                  >
                    Access to API WA
                  </Checkbox>
                  <Checkbox
                    isChecked={access.send_message_email}
                    onChange={(e) => setAccess({ ...access, send_message_email: e.target.checked })}
                    isDisabled={license !== "premium"}
                  >
                    Access to Send Message Email
                  </Checkbox>
                  <Checkbox
                    isChecked={access.api_email_otp}
                    onChange={(e) => setAccess({ ...access, api_email_otp: e.target.checked })}
                    isDisabled={license !== "premium"}
                  >
                    Access to API Email OTP
                  </Checkbox>
                </SimpleGrid>
              </Box>

              {/* Message Configuration */}
              <Box borderWidth="1px" borderRadius="lg" p={4} w="100%">
                <Box fontWeight="bold" mb={2}>Message Configuration</Box>
                <FormControl>
                  <FormLabel>WA Message Limit</FormLabel>
                  <Input
                    type="number"
                    placeholder="Enter WA message limit"
                    value={waMessageLimit}
                    onChange={(e) => setWaMessageLimit(e.target.value)}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Email Message Limit</FormLabel>
                  <Input
                    type="number"
                    placeholder="Enter email message limit"
                    value={emailMessageLimit}
                    onChange={(e) => setEmailMessageLimit(e.target.value)}
                  />
                </FormControl>
              </Box>

            </VStack>
          </DrawerBody>
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={handleSave}>
              Save
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default EditLicenseDrawer;
