import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignUp from "./pages/SignUpPage";
import Login from "./pages/LoginPage";
import Home from "./pages/HomePage";
import BlastPesan from "./pages/BlastPage";
import Profile from "./pages/ProfilePage";
import Dashboard from "./pages/DashboardPage";
import NotFound from "./pages/Page404";
import LoadingSpinner from "./components/ui/loading-spinner";
import useAuthentication from "./useAuthentication";
import DashboardBlast from "./pages/DBBlastPage";
import DashboardSetting from "./pages/DBSettingPage";
import Dbdashboard from "./pages/DBDashboardPage.js";
import DashboardGBlast from './pages/DBGBlastPage.js';
import ForgotPasswordPage from './pages/ForgotPasswordPage.js'; // Import the ForgotPasswordPage component
import ResetPassword from './pages/ResetPasswordPage.js';

function App() {
  const { isLoggedIn, isLoading } = useAuthentication();


  return (
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="login" element={<Login />} />
          <Route path="forgot-password" element={<ForgotPasswordPage />} /> {/* Add the new route here */}
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="blast" element={<BlastPesan />} />
          <Route path="dbblast" element={<DashboardBlast />} />
          <Route path="dbgblast" element={<DashboardGBlast />} />
          <Route path="dbsetting" element={<DashboardSetting />} />
          <Route path="profile" element={<Profile />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="dbdashboard" element={<Dbdashboard />} />
          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
    </BrowserRouter>
  );
}

export default App;