import React, { useState, useEffect, useContext, useRef } from "react";
import {
    Box,
    Heading,
    Flex,
    useToast,
    Button,
    Textarea,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Checkbox,
    Spinner,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    IconButton,
} from "@chakra-ui/react";
import AvatarMenu from "../components/navbar/avatar-menu";
import SidebarContent from "../components/dashboard/sidebar-content";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Navbar from "../components/navbar/Navbar";
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ViewIcon } from "@chakra-ui/icons";
import moment from 'moment-timezone';

function DashboardGBlast() {
    const { t } = useTranslation();
    const toast = useToast();
    const [loading, setLoading] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [groups, setGroups] = useState([]);
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [message, setMessage] = useState("");
    const [view, setView] = useState("selection");
    const [showGroupModal, setShowGroupModal] = useState(false);
    const [groupUsers, setGroupUsers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const setLastType = (type) => {
        localStorage.setItem("lastType", type);
    };
    const navigate = useNavigate();
    const [selectedGroups, setSelectedGroups] = useState([]);
    const userId = localStorage.getItem("id");
    const toastShownRef = useRef(false);

    useEffect(() => {
        const storedId = localStorage.getItem("id");
        const userLicense = localStorage.getItem("license");
    
        if (!storedId) {
            navigate('/login');
        } else if (userLicense === "trial") {
            if (!toastShownRef.current) {
                toast({
                    title: "License Trial",
                    description: "Perbarui License Anda Untuk Mengakses Halaman Ini.",
                    status: "warning",
                    duration: 5000,
                    isClosable: true,
                });
                toastShownRef.current = true;
                navigate('/dashboard');
            }
        } else {
            setLastType("Blast");
            fetchContacts();
            fetchGroups();
        }
    }, [navigate, toast]);
    
    useEffect(() => {
        setSelectedContacts([]);
        setSelectedGroups([]);
    }, [view]);

    const handleData = (type) => {
        setLoading(true);
        if (type === "Users") {
            setLoading(false);
            setLastType("Users")
        } else if (type === "Contacts") {
            setLoading(false);
            setLastType("Contacts")
        } else if (type === "Groups") {
            setLoading(false);
            setLastType("Groups")
        } else if (type === "Reports") {
            setLoading(false);
            setLastType("Reports")
        } else if (type === "EReports") {
            setLoading(false);
            setLastType("EReports")
        } else if (type === "Blast") {
            setLoading(false);
            setLastType("Blast")
            navigate('/dbblast');
        } else if (type === "Setting") {
            setLoading(false);
            setLastType("Setting")
            navigate('/dbsetting');
        } else if (type === "Dashboard") {
            setLoading(false);
            setLastType("Dashboard")
            navigate('/dbdashboard');
        }
    };

    const fetchContacts = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`https://api.pawarta.awandigital.id/api/contacts?user_id=${userId}`);
            if (response.data && Array.isArray(response.data.data)) {
                setContacts(response.data.data);
            } else {
                toast({
                    title: "Error",
                    description: "Failed to fetch contacts",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
            setLoading(false);
        } catch (error) {
            console.error("Failed to fetch contacts:", error);
            setLoading(false);
            toast({
                title: "Error",
                description: "Failed to fetch contacts",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const fetchGroups = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`https://api.pawarta.awandigital.id/api/groups?user_id=${userId}`);
            if (response.data && Array.isArray(response.data.data)) {
                const groupsWithContacts = response.data.data.map(group => ({
                    ...group,
                    contacts: group.contacts || [] // Pastikan properti contacts ada atau diinisialisasi dengan array kosong
                }));
                setGroups(groupsWithContacts);
            } else {
                toast({
                    title: "Error",
                    description: "Failed to fetch groups: unexpected data format",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
            setLoading(false);
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to fetch groups",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };    

    const handleSelectAll = () => {
        if (selectedContacts.length === contacts.length) {
            setSelectedContacts([]);
        } else {
            setSelectedContacts(contacts.map(contact => contact.id));
        }
    };

    const handleSelectAllGroups = async () => {
        if (selectedGroups.length === groups.length) {
            setSelectedGroups([]);
            setSelectedContacts([]);
        } else {
            const newSelectedGroups = groups.map(group => group.id);
            setSelectedGroups(newSelectedGroups);
    
            const newSelectedContacts = [];
            for (const groupId of newSelectedGroups) {
                const groupContacts = await fetchGroupContacts(groupId);
                newSelectedContacts.push(...groupContacts.map(contact => contact.id));
            }
            setSelectedContacts(newSelectedContacts);
        }
    };    

    const handleSelectGroup = async (groupId) => {
        setSelectedGroups(prevSelected => {
            const isSelected = prevSelected.includes(groupId);
            const newSelectedGroups = isSelected
                ? prevSelected.filter(id => id !== groupId)
                : [...prevSelected, groupId];
    
            updateSelectedContacts(newSelectedGroups);
    
            return newSelectedGroups;
        });
    };
    
    const updateSelectedContacts = async (newSelectedGroups) => {
        const newSelectedContacts = new Set();
    
        for (const groupId of newSelectedGroups) {
            const groupContacts = await fetchGroupContacts(groupId);
            groupContacts.forEach(contact => newSelectedContacts.add(contact.id));
        }
    
        setSelectedContacts([...newSelectedContacts]);
    };    

    const handleSelectContact = (contactId) => {
        setSelectedContacts(prevSelected =>
            prevSelected.includes(contactId)
                ? prevSelected.filter(id => id !== contactId)
                : [...prevSelected, contactId]
        );
    };

    const saveEmailReports = async (personalizedMessages) => {
        try {
            const response = await axios.post("https://api.pawarta.awandigital.id/api/email-reports", {
                reports: personalizedMessages,
            });
    
            if (response.data.status) {
                console.log('Email reports saved successfully:', response.data);
            } else {
                console.error('Failed to save email reports:', response.data);
            }
        } catch (error) {
            console.error('Failed to save email reports:', error);
        }
    };

    const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

    const handleSendMessages = async () => {
        if (selectedContacts.length === 0 && selectedGroups.length === 0) {
            toast({
                title: "Error",
                description: "No contacts or groups selected",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            return;
        }
    
        const formattedContacts = contacts
            .filter(contact => selectedContacts.includes(contact.id))
            .map(contact => ({
                email: contact.email,
                name: contact.name
            }));
    
        try {
            setLoading(true);
            const personalizedMessages = formattedContacts.map(contact => ({
                email: contact.email,
                message: message,
                name: contact.name,
                status: 'Sent',
                user_id: userId,
                timestamp: moment().tz("Asia/Jakarta").format("YYYY-MM-DD HH:mm:ss")
            }));
    
            for (const message of personalizedMessages) {
                const emailHeaders = {
                    'X-Custom-Header': 'Prevent-Spam'
                };
    
                const response = await axios.post("https://api.pawarta.awandigital.id/api/send-email", {
                    contacts: [message],
                }, {
                    headers: emailHeaders
                });
    
                if (response.data.status) {
                    await saveEmailReports([message]);
                    toast({
                        title: "Success",
                        description: `Messages sent to ${formattedContacts.length} contacts successfully`,
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    });
                } else {
                    toast({
                        title: "Error",
                        description: "Failed to send messages",
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                    });
                }
    
                await delay(5000);
            }
    
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("Failed to send messages:", error);
            toast({
                title: "Error",
                description: "Failed to send messages",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };    

    const fetchGroupContacts = async (groupId) => {
        if (!groupId) {
            toast({
                title: "Error",
                description: "Group ID is not defined",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            return [];
        }
        try {
            const response = await axios.get(`https://api.pawarta.awandigital.id/api/groups/${groupId}/contacts`);
            if (response.data && Array.isArray(response.data.data)) {
                return response.data.data;
            } else {
                toast({
                    title: "Error",
                    description: "Failed to fetch group contacts",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
                return [];
            }
        } catch (error) {
            console.error(`Failed to fetch contacts for group ${groupId}:`, error);
            toast({
                title: "Error",
                description: `Failed to fetch contacts for group ${groupId}`,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            return [];
        }
    };    

    const handleViewGroup = async (groupId) => {
        const group = groups.find(g => g.id === groupId);
        if (group) {
            setSelectedGroup(group);
            setShowGroupModal(true);
            
            const users = await fetchGroupContacts(groupId);
            setGroupUsers(users);
        } else {
            toast({
                title: "Error",
                description: "Group not found",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const closeGroupModal = () => {
        setShowGroupModal(false);
        setSelectedGroup(null);
        setGroupUsers([]);
    };

    return (
        <>
            <Navbar
                sidebarContent={<SidebarContent handleData={handleData} />}
                buttons={
                    <>
                        <Heading>Blast</Heading>
                        <AvatarMenu />
                    </>
                }
            />
            <Box
                as="section"
                minH="100vh"
                paddingLeft="20px"
                paddingRight="20px"
                ml={{
                    base: 0,
                    md: 60,
                }}
                transition=".3s ease"
            >
                <Heading as="h1" size="lg" mb={4}>
                    {t("Email Blast")}
                </Heading>

                <Box mb={4} p={4} borderWidth="2px" borderRadius="lg">
                    <Flex justifyContent="center" mb={4}>
                        <Button mr={2} onClick={() => setView("contacts")}>
                            {t("Show Contacts")}
                        </Button>
                        <Button onClick={() => setView("groups")}>
                            {t("Show Groups")}
                        </Button>
                    </Flex>

                    {view === "contacts" && (
                        <>
                            <Heading as="h2" size="md" mb={4}>
                                {t("Kontak")}
                            </Heading>
                            <Table variant="striped">
                                <Thead>
                                    <Tr>
                                        <Th>
                                            <Checkbox
                                                isChecked={selectedContacts.length === contacts.length}
                                                onChange={handleSelectAll}
                                            />
                                        </Th>
                                        <Th>{t("Name")}</Th>
                                        <Th>{t("Email")}</Th> {/* Ubah ini menjadi email */}
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {contacts.map(contact => (
                                        <Tr key={contact.id}>
                                            <Td>
                                                <Checkbox
                                                    isChecked={selectedContacts.includes(contact.id)}
                                                    onChange={() => handleSelectContact(contact.id)}
                                                />
                                            </Td>
                                            <Td>{contact.name}</Td>
                                            <Td>{contact.email}</Td> {/* Ubah ini menjadi email */}
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </>
                    )}

                    {view === "groups" && (
                        <>
                            <Heading as="h2" size="md" mb={4}>
                                {t("Groups")}
                            </Heading>
                            <Table variant="striped">
                                <Thead>
                                    <Tr>
                                        <Th>
                                            <Checkbox
                                                isChecked={selectedGroups.length === groups.length}
                                                onChange={handleSelectAllGroups}
                                            />
                                        </Th>
                                        <Th>{t("Name")}</Th>
                                        <Th>{t("View")}</Th> {/* Hapus kolom Contacts */}
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {groups.map(group => (
                                        <Tr key={group.id}>
                                            <Td>
                                                <Checkbox
                                                    isChecked={selectedGroups.includes(group.id)}
                                                    onChange={() => handleSelectGroup(group.id)}
                                                />
                                            </Td>
                                            <Td>{group.group_name}</Td>
                                            <Td>
                                                <IconButton onClick={() => handleViewGroup(group.id)} icon={<ViewIcon/>}>
                                                </IconButton>
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </>
                    )}
                </Box>

                <Box mb={4} p={4} borderWidth="2px" borderRadius="lg">
                    <Heading as="h2" mb="4">
                        {t("waDBBlast.sendMessage")}
                    </Heading>
                    <ReactQuill value={message} onChange={setMessage} />

                    <Button
                        colorScheme="green"
                        onClick={handleSendMessages}
                        isLoading={loading}
                    >
                        {t("Send")}
                    </Button>
                </Box>
                <Modal isOpen={showGroupModal} onClose={closeGroupModal}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>{selectedGroup?.group_name}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Table variant="simple" mb={4}>
                                <Thead>
                                    <Tr>
                                        <Th>{t("name")}</Th>
                                        <Th>{t("email")}</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {groupUsers.map((user) => (
                                        <Tr key={user.id}>
                                            <Td>{user.name}</Td>
                                            <Td>{user.email}</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="red" mr={3} onClick={closeGroupModal}>
                                {t("close")}
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Box>
        </>
    );
}

export default DashboardGBlast;
