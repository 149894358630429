import React, { useState, useEffect, useContext, useRef } from "react";
import {
    Box,
    Heading,
    Flex,
    useToast,
    Button,
    Textarea,
    Text,
    Input,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Checkbox,
    Spinner,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    IconButton,
} from "@chakra-ui/react";
import AvatarMenu from "../components/navbar/avatar-menu";
import SidebarContent from "../components/dashboard/sidebar-content";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Navbar from "../components/navbar/Navbar";
import { showToast } from "../components/toast-alert";
import { useNavigate } from 'react-router-dom';
import { ViewIcon, RepeatIcon, CopyIcon, ViewOffIcon } from "@chakra-ui/icons";
import moment from 'moment-timezone';

function DashboardBlast() {
    const { t } = useTranslation();
    const toast = useToast();
    const ws = useRef(null);
    const [loading, setLoading] = useState(false);
    const [qrGenerated, setQrGenerated] = useState(false);
    const [qrPath, setQrPath] = useState("");
    const [contacts, setContacts] = useState([]);
    const [groups, setGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [message, setMessage] = useState("");
    const [media, setMedia] = useState("");
    const [filename, setFilename] = useState("");
    const [view, setView] = useState("selection");
    const [showGroupModal, setShowGroupModal] = useState(false);
    const [groupUsers, setGroupUsers] = useState([]);
    const [connectionStatus, setConnectionStatus] = useState("disconnected");
    const MAX_MEDIA_SIZE = 10 * 1024 * 1024;
    const setLastType = (type) => {
        localStorage.setItem("lastType", type);
    };
    const navigate = useNavigate();
    const userId = localStorage.getItem("id");
    const toastShownRef = useRef(false);

    const [apiKey, setApiKey] = useState(localStorage.getItem("apiKey") || "");
    const [isGeneratingKey, setIsGeneratingKey] = useState(false);
    const [isApiKeyVisible, setIsApiKeyVisible] = useState(false);
    const [isSendingTestMessage, setIsSendingTestMessage] = useState(false);


    useEffect(() => {
        const storedId = localStorage.getItem("id");
        const userLicense = localStorage.getItem("license");

        if (!storedId) {
            navigate('/login');
        } else if (userLicense === "trial") {
            if (!toastShownRef.current) {
                toast({
                    title: "License Trial",
                    description: "Perbarui License Anda Untuk Mengakses Halaman Ini.",
                    status: "warning",
                    duration: 5000,
                    isClosable: true,
                });
                toastShownRef.current = true;
                navigate('/dashboard');
            }
        } else {
            setLastType("Blast");
            fetchContacts();
            fetchGroups();
            // setupWebSocket();
        }
    }, [navigate, toast]);

    useEffect(() => {
        setSelectedContacts([]);
        setSelectedGroups([]);
    }, [view]);

    // const setupWebSocket = () => {
    //     ws.current = new WebSocket('ws://blastengine.awandigital.id'); // WebSocket URL

    //     ws.current.onopen = () => {
    //         console.log('WebSocket connected');
    //     };

    //     ws.current.onmessage = (event) => {
    //         const data = JSON.parse(event.data);
    //         console.log('WebSocket message received:', data);

    //         switch (data.type) {
    //             case 'connected':
    //                 setConnectionStatus('connected');
    //                 toast({
    //                     title: "Connected",
    //                     description: "You are now connected.",
    //                     status: "success",
    //                     duration: 5000,
    //                     isClosable: true,
    //                 });
    //                 break;
    //             case 'connecting':
    //                 setConnectionStatus('connecting');
    //                 toast({
    //                     title: "Connecting",
    //                     description: "Attempting to connect...",
    //                     status: "info",
    //                     duration: 5000,
    //                     isClosable: true,
    //                 });
    //                 break;
    //             case 'disconnect':
    //                 setConnectionStatus('disconnected');
    //                 toast({
    //                     title: "Disconnected",
    //                     description: "You have been disconnected.",
    //                     status: "warning",
    //                     duration: 5000,
    //                     isClosable: true,
    //                 });
    //                 break;
    //             case 'info':
    //                 console.log('Info message received:', data.message);
    //                 toast({
    //                     title: "Info",
    //                     description: data.message,
    //                     status: "info",
    //                     duration: 5000,
    //                     isClosable: true,
    //                 });
    //                 break;
    //             default:
    //                 console.log('Unknown message type:', data.type);
    //         }
    //     };

    //     ws.current.onerror = (error) => {
    //         console.error('WebSocket error:', error);
    //         toast({
    //             title: "Error",
    //             description: "WebSocket error occurred.",
    //             status: "error",
    //             duration: 5000,
    //             isClosable: true,
    //         });
    //     };

    //     ws.current.onclose = () => {
    //         console.log('WebSocket disconnected');
    //     };
    // };

    const handleData = (type) => {
        setLoading(true);
        if (type === "Users") {
            setLoading(false);
            setLastType("Users")
        } else if (type === "Contacts") {
            setLoading(false);
            setLastType("Contacts")
        } else if (type === "Groups") {
            setLoading(false);
            setLastType("Groups")
        } else if (type === "Reports") {
            setLoading(false);
            setLastType("Reports")
        } else if (type === "EReports") {
            setLoading(false);
            setLastType("EReports")
        } else if (type === "Gmail") {
            setLoading(false);
            setLastType("Gmail")
            navigate('/dbgblast');
        } else if (type === "Setting") {
            setLoading(false);
            setLastType("Setting")
            navigate('/dbsetting');
        } else if (type === "Dashboard") {
            setLoading(false);
            setLastType("Dashboard")
            navigate('/dbdashboard');
        }
    };

    const fetchContacts = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`https://api.pawarta.awandigital.id/api/contacts?user_id=${userId}`);
            if (response.data && Array.isArray(response.data.data)) {
                setContacts(response.data.data);
            } else {
                toast({
                    title: "Error",
                    description: "Failed to fetch contacts",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
            setLoading(false);
        } catch (error) {
            console.error("Failed to fetch contacts:", error);
            setLoading(false);
            toast({
                title: "Error",
                description: "Failed to fetch contacts",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const fetchGroups = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`https://api.pawarta.awandigital.id/api/groups?user_id=${userId}`);
            if (response.data && Array.isArray(response.data.data)) {
                const groupsWithContacts = response.data.data.map(group => ({
                    ...group,
                    contacts: group.contacts || []
                }));
                setGroups(groupsWithContacts);
            } else {
                toast({
                    title: "Error",
                    description: "Failed to fetch groups: unexpected data format",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
            setLoading(false);
        } catch (error) {
            console.error("Failed to fetch groups:", error);
            setLoading(false);
            toast({
                title: "Error",
                description: "Failed to fetch groups",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleSelectAll = () => {
        if (selectedContacts.length === contacts.length) {
            setSelectedContacts([]);
        } else {
            setSelectedContacts(contacts.map(contact => contact.id));
        }
    };

    const handleSelectAllGroups = async () => {
        if (selectedGroups.length === groups.length) {
            setSelectedGroups([]);
            setSelectedContacts([]);
        } else {
            const newSelectedGroups = groups.map(group => group.id);
            setSelectedGroups(newSelectedGroups);

            const newSelectedContacts = [];
            for (const groupId of newSelectedGroups) {
                const groupContacts = await fetchGroupContacts(groupId);
                newSelectedContacts.push(...groupContacts.map(contact => contact.id));
            }
            setSelectedContacts(newSelectedContacts);
        }
    };

    const handleSelectGroup = async (groupId) => {
        setSelectedGroups(prevSelected => {
            const isSelected = prevSelected.includes(groupId);
            const newSelectedGroups = isSelected
                ? prevSelected.filter(id => id !== groupId)
                : [...prevSelected, groupId];

            updateSelectedContacts(newSelectedGroups);

            return newSelectedGroups;
        });
    };

    const updateSelectedContacts = async (newSelectedGroups) => {
        const newSelectedContacts = new Set();

        for (const groupId of newSelectedGroups) {
            const groupContacts = await fetchGroupContacts(groupId);
            groupContacts.forEach(contact => newSelectedContacts.add(contact.id));
        }

        setSelectedContacts([...newSelectedContacts]);
    };

    const handleSelectContact = (contactId) => {
        setSelectedContacts(prevSelected =>
            prevSelected.includes(contactId)
                ? prevSelected.filter(id => id !== contactId)
                : [...prevSelected, contactId]
        );
    };

    useEffect(() => {
        if (connectionStatus === "connected") {
            setLoading(false);
        }
    }, [connectionStatus]);

    const generateQR = async () => {
        const sessionId = localStorage.getItem("id") || "mysession";
        console.log("Session ID:", sessionId);

        if (!sessionId) {
            toast({
                title: "Error",
                description: "Session ID tidak ditemukan",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            return;
        }

        try {
            setLoading(true);
            console.log("Sending request to generate QR code");

            const response = await axios.get(`https://blastengine.awandigital.id/start-session?session=${sessionId}&scan=true`);
            console.log("Response received:", response);
            console.log("Response status:", response.status);
            console.log("Response data:", response.data);

            if (response.status === 400 && response.data.message.includes('Session ID')) {
                console.log("Session already exists.");
                setConnectionStatus("connected");
                toast({
                    title: "Info",
                    description: response.data.message,
                    status: "info",
                    duration: 9000,
                    isClosable: true,
                });
            } else {
                const qrCodeRegex = /let qr = '(.+?)'/;
                const qrCodeMatch = response.data.match(qrCodeRegex);

                if (qrCodeMatch) {
                    const qrCode = qrCodeMatch[1];
                    console.log("QR Code extracted:", qrCode);
                    setQrPath(qrCode);
                    setQrGenerated(true);
                    setConnectionStatus("connected");
                } else {
                    toast({
                        title: "Error",
                        description: "QR code not found in the response",
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                    });
                }
            }

            setLoading(false);
        } catch (error) {
            console.error("Failed to generate QR code:", error);
            setLoading(false);
            toast({
                title: "Error",
                description: "Failed to generate QR code",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleRegenerateQR = async () => {
        await handleDeleteSession();
        await generateQR();
    };

    const handleDeleteSession = async () => {
        const sessionId = localStorage.getItem("id") || "mysession";
        if (!sessionId) {
            toast({
                title: "Error",
                description: "Session ID tidak ditemukan",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            return;
        }

        try {
            setLoading(true);
            await axios.get(`https://blastengine.awandigital.id/delete-session?session=${sessionId}`);
            setQrGenerated(false);
            setQrPath("");
            toast({
                title: "Success",
                description: "Session deleted successfully",
                status: "success",
                duration: 9000,
                isClosable: true,
            });
            setLoading(false);
        } catch (error) {
            console.error("Failed to delete session:", error);
            setLoading(false);
            toast({
                title: "Error",
                description: "Failed to delete session",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const formatPhoneNumber = (phone) => {
        return phone.startsWith('0') ? '62' + phone.slice(1) : phone;
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > MAX_MEDIA_SIZE) {
                toast({
                    title: "Error",
                    description: "File size exceeds the maximum limit of 10MB",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
                return;
            }

            const formData = new FormData();
            formData.append('file', file);

            try {
                const response = await axios.post('https://api.pawarta.awandigital.id/api/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                if (response.data && response.data.filename) {
                    const fileUrl = response.data.filename;
                    setMedia(fileUrl);
                    setFilename(file.name);
                    toast({
                        title: "Success",
                        description: "File uploaded successfully",
                        status: "success",
                        duration: 9000,
                        isClosable: true,
                    });
                } else {
                    toast({
                        title: "Error",
                        description: "Failed to upload file",
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                    });
                }
            } catch (error) {
                console.error("Failed to upload file:", error);
                toast({
                    title: "Error",
                    description: "Failed to upload file",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        }
    };

    const sendMessagesToReports = async () => {
        const formattedContacts = contacts
            .filter(contact => selectedContacts.includes(contact.id))
            .map(contact => ({
                message,
                telephone: contact.phone,
                name: contact.name,
                status: "Sent",
                timestamp: moment().tz("Asia/Jakarta").format("YYYY-MM-DD HH:mm:ss"),
                user_id: userId
            }));

        try {
            setLoading(true);
            await axios.post("https://api.pawarta.awandigital.id/api/reports", formattedContacts);
            setLoading(false);
        } catch (error) {
            console.error("Failed to save messages to reports:", error);
            setLoading(false);
            toast({
                title: "Error",
                description: "Failed to save messages to reports",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleGenerateApiKey = async () => {
        setIsGeneratingKey(true);
        try {
            const userId = localStorage.getItem("id");
            console.log("User ID from localStorage:", userId);

            if (!userId) {
                throw new Error("User ID tidak ditemukan di localStorage.");
            }

            const tokenResponse = await axios.get("https://api.pawarta.awandigital.id/api/get-user-token", {
                params: { user_id: userId },
            });

            if (!tokenResponse.data.success) {
                throw new Error(tokenResponse.data.message || "Failed to fetch token");
            }

            const token = tokenResponse.data.token;
            console.log("Token fetched:", token);

            const response = await axios.get("https://api.pawarta.awandigital.id/api/get-api-key", {
                params: { user_id: userId },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            console.log("API response:", response.data);

            if (response.data.success) {
                const newApiKey = response.data.api_key;
                setApiKey(newApiKey);
                localStorage.setItem("apiKey", newApiKey);

                toast({
                    title: "Success",
                    description: "API key fetched successfully",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: "Error",
                    description: response.data.message || "Failed to fetch API key",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error("Failed to fetch API key:", error);
            toast({
                title: "Error",
                description: `Failed to fetch API key: ${error.message}`,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        } finally {
            setIsGeneratingKey(false);
        }
    };

    const handleDeleteApiKey = () => {
        setApiKey("");
        localStorage.removeItem("apiKey");
    };

    const handleCopyApiKey = () => {
        if (apiKey) {
            navigator.clipboard.writeText(apiKey)
                .then(() => {
                    toast({
                        title: "Success",
                        description: "API key copied to clipboard",
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    });
                })
                .catch(() => {
                    toast({
                        title: "Error",
                        description: "Failed to copy API key",
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                });
        } else {
            toast({
                title: "Error",
                description: "No API key to copy",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const toggleApiKeyVisibility = () => {
        setIsApiKeyVisible(!isApiKeyVisible);
    };

    const handleSendTestMessage = async () => {
        let apiKey = localStorage.getItem("apiKey");
        const userId = localStorage.getItem("id");
        let testPhoneNumber = localStorage.getItem("telephone");
    
        testPhoneNumber = formatPhoneNumber(testPhoneNumber);
        
        if (!userId) {
            toast({
                title: "Error",
                description: "User ID not found in localStorage.",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            return;
        }
    
        if (!testPhoneNumber) {
            toast({
                title: "Error",
                description: "Phone number not found in localStorage.",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            return;
        }
    
        if (!apiKey) {
            try {
                const tokenResponse = await axios.get("https://api.pawarta.awandigital.id/api/get-user-token", {
                    params: { user_id: userId },
                });
    
                if (tokenResponse.data.success) {
                    apiKey = tokenResponse.data.token;
                    localStorage.setItem("apiKey", apiKey);
                } else {
                    toast({
                        title: "Error",
                        description: "No API key or token available. Please generate an API key first.",
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                    });
                    return;
                }
            } catch (error) {
                toast({
                    title: "Error",
                    description: `Failed to fetch token: ${error.message}`,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
                return;
            }
        }
    
        let templatePreview = "";
        try {
            const templateResponse = await axios.get(`https://api.pawarta.awandigital.id/api/get-user-template/${userId}`, {
                headers: {
                    'x-api-key': apiKey,
                },
            });
    
            if (templateResponse.data.status && templateResponse.data.data.template) {
                templatePreview = templateResponse.data.data.template.preview_template || "This is a test message";
            } else {
                templatePreview = "This is a test message";
            }
        } catch (error) {
            console.error("Error fetching template:", error);
            templatePreview = "This is a test message";
        }
    
        try {
            setIsSendingTestMessage(true);
    
            const response = await axios.post("https://api.pawarta.awandigital.id/api/send-test-message", {
                session: userId,
                testPhoneNumber: testPhoneNumber,
                testMessage: templatePreview,
            }, {
                headers: {
                    "Content-Type": "application/json",
                    'x-api-key': apiKey,
                },
            });
    
            if (response.data.status) {
                toast({
                    title: "Success",
                    description: "Test message sent successfully",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: "Error",
                    description: "Failed to send test message",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error("Failed to send test message:", error);
            toast({
                title: "Error",
                description: `Failed to send test message: ${error.message}`,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        } finally {
            setIsSendingTestMessage(false);
        }
    };
    
    const handleSendMessages = async () => {
        let apiKey = localStorage.getItem("apiKey");
        const userId = localStorage.getItem("id");

        if (!userId) {
            toast({
                title: "Error",
                description: "User ID tidak ditemukan di localStorage.",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            return;
        }

        if (!apiKey) {
            try {
                const tokenResponse = await axios.get("https://api.pawarta.awandigital.id/api/get-user-token", {
                    params: { user_id: userId },
                });

                if (tokenResponse.data.success) {
                    apiKey = tokenResponse.data.token;
                    localStorage.setItem("apiKey", apiKey);
                } else {
                    toast({
                        title: "Error",
                        description: "No API key or token available. Please generate an API key first.",
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                    });
                    return;
                }
            } catch (error) {
                toast({
                    title: "Error",
                    description: `Failed to fetch token: ${error.message}`,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
                return;
            }
        }

        if (selectedContacts.length === 0 && selectedGroups.length === 0) {
            toast({
                title: "Error",
                description: "No contacts or groups selected",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            return;
        }

        const selectedGroupContacts = selectedGroups.flatMap(groupId => {
            const group = groups.find(group => group.id === groupId);
            if (group) {
                return group.contacts.map(contact => contact.id);
            }
            return [];
        });

        const allContacts = new Set([...selectedContacts, ...selectedGroupContacts]);
        const timestamp = moment().tz("Asia/Jakarta").format("YYYY-MM-DD HH:mm:ss");

        const formattedContacts = contacts
            .filter(contact => allContacts.has(contact.id))
            .map(contact => ({
                to: formatPhoneNumber(contact.phone),
                text: message,
                media,
                filename,
                message,
                telephone: contact.phone,
                name: contact.name,
                status: "Sent",
                user_id: userId,
                timestamp: timestamp,
            }));

        try {
            setLoading(true);
            const response = await axios.post(
                "https://blastengine.awandigital.id/send-bulk-message",
                {
                    session: userId,
                    data: formattedContacts,
                    delay: 1000,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${apiKey}`, // Menggunakan Bearer Token
                    },
                }
            );

            if (response.data.status) {
                toast({
                    title: "Success",
                    description: "Messages sent successfully",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                });
                await sendMessagesToReports();
            } else {
                toast({
                    title: "Error",
                    description: "Failed to send messages",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error("Failed to send messages:", error);
            toast({
                title: "Error",
                description: `Failed to send messages: ${error.message}`,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const fetchGroupContacts = async (groupId) => {
        if (!groupId) {
            toast({
                title: "Error",
                description: "Group ID is not defined",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            return [];
        }
        try {
            const response = await axios.get(`https://api.pawarta.awandigital.id/api/groups/${groupId}/contacts`);
            if (response.data && Array.isArray(response.data.data)) {
                return response.data.data;
            } else {
                toast({
                    title: "Error",
                    description: "Failed to fetch group contacts",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
                return [];
            }
        } catch (error) {
            console.error(`Failed to fetch contacts for group ${groupId}:`, error);
            toast({
                title: "Error",
                description: `Failed to fetch contacts for group ${groupId}`,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            return [];
        }
    };

    const handleViewGroup = async (groupId) => {
        const group = groups.find(g => g.id === groupId);
        if (group) {
            setSelectedGroup(group);
            setShowGroupModal(true);

            const users = await fetchGroupContacts(groupId);
            setGroupUsers(users);
        } else {
            toast({
                title: "Error",
                description: "Group not found",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const closeGroupModal = () => {
        setShowGroupModal(false);
        setSelectedGroup(null);
        setGroupUsers([]);
    };

    return (
        <>
            <Navbar
                sidebarContent={<SidebarContent handleData={handleData} />}
                buttons={
                    <>
                        <Heading>Blast</Heading>
                        <AvatarMenu />
                    </>
                }
            />

            <Box
                as="section"
                minH="100vh"
                paddingLeft="20px"
                paddingRight="20px"
                ml={{
                    base: 0,
                    md: 60,
                }}
                transition=".3s ease"
            >
                <Flex direction="row" justifyContent="space-between" alignItems="center" mb={4}>
                    <Heading as="h1" size="lg">
                        {t("Whatsapp Blast")}
                    </Heading>
                    <Button
                        colorScheme="red"
                        onClick={handleDeleteSession}
                        isLoading={loading}
                    >
                        Delete Session
                    </Button>
                </Flex>

                <Box borderWidth="2px" borderStyle="solid" borderColor="gray.300" rounded="md" p="4" mt="4" w="full">
                    <Heading as="h2" size="md" mb={4}>
                        API Key Management
                    </Heading>

                    <Button
                        colorScheme={apiKey ? "red" : "blue"}
                        onClick={apiKey ? handleDeleteApiKey : handleGenerateApiKey}
                        isLoading={isGeneratingKey}
                    >
                        {apiKey ? "Hapus API Key" : "Generate API Key"}
                    </Button>

                    {apiKey && (
                        <Box mt={4}>
                            <Text>Your API Key:</Text>
                            <Flex>
                                <Input
                                    type={isApiKeyVisible ? "text" : "password"}
                                    value={apiKey}
                                    isReadOnly
                                />
                                <IconButton
                                    ml={2}
                                    colorScheme="teal"
                                    icon={<CopyIcon />}
                                    onClick={handleCopyApiKey}
                                    aria-label="Copy API Key"
                                />
                                <IconButton
                                    ml={2}
                                    colorScheme="teal"
                                    icon={isApiKeyVisible ? <ViewOffIcon /> : <ViewIcon />}
                                    onClick={toggleApiKeyVisibility}
                                    aria-label={isApiKeyVisible ? "Hide API Key" : "Show API Key"}
                                />
                            </Flex>
                        </Box>
                    )}

                    {apiKey && (
                        <Button
                            mt={4}
                            colorScheme="green"
                            onClick={handleSendTestMessage}
                            isLoading={isSendingTestMessage}
                        >
                            Kirim Pesan Test
                        </Button>
                    )}
                </Box>

                <Flex align="center" justify="center" direction="column">
                    <Box
                        borderWidth="2px"
                        borderStyle="solid"
                        borderColor="gray.300"
                        rounded="md"
                        p="2"
                        my="8"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        width="fit-content"
                    >
                        {qrGenerated ? (
                            <Box>
                                <img src={qrPath} alt="QR Code" />
                                <Flex justify="center" mt={4}>
                                    <IconButton
                                        colorScheme="teal"
                                        aria-label="Regenerate QR"
                                        icon={<RepeatIcon />}
                                        onClick={handleRegenerateQR}
                                    />
                                </Flex>
                            </Box>
                        ) : (
                            <Button
                                colorScheme="green"
                                onClick={generateQR}
                                isDisabled={connectionStatus === "connected"} // Nonaktifkan tombol jika sudah terkoneksi
                                isLoading={loading} // Tambahkan loading state untuk feedback visual
                            >
                                {connectionStatus === "connected" ? "Connected" : "Generate QR"}
                            </Button>
                        )}
                    </Box>
                    <Box borderWidth="2px" borderStyle="solid" borderColor="gray.300" rounded="md" p="4" m="4" w="full">
                        <Flex justifyContent="center" mb={4}>
                            <Button mr={2} onClick={() => setView("contacts")}>
                                {t("Show Contacts")}
                            </Button>
                            <Button onClick={() => setView("groups")}>
                                {t("Show Groups")}
                            </Button>
                        </Flex>

                        {view === "contacts" && (
                            <>
                                <Heading as="h2" size="md" mb={4}>
                                    {t("Kontak")}
                                </Heading>
                                <Table variant="striped">
                                    <Thead>
                                        <Tr>
                                            <Th>
                                                <Checkbox
                                                    isChecked={selectedContacts.length === contacts.length}
                                                    onChange={handleSelectAll}
                                                />
                                            </Th>
                                            <Th>{t("Name")}</Th>
                                            <Th>{t("Phone")}</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {contacts.map(contact => (
                                            <Tr key={contact.id}>
                                                <Td>
                                                    <Checkbox
                                                        isChecked={selectedContacts.includes(contact.id)}
                                                        onChange={() => handleSelectContact(contact.id)}
                                                    />
                                                </Td>
                                                <Td>{contact.name}</Td>
                                                <Td>{contact.phone}</Td> {/* Ubah ini menjadi email */}
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </>
                        )}

                        {view === "groups" && (
                            <>
                                <Heading as="h2" size="md" mb={4}>
                                    {t("Groups")}
                                </Heading>
                                <Table variant="striped">
                                    <Thead>
                                        <Tr>
                                            <Th>
                                                <Checkbox
                                                    isChecked={selectedGroups.length === groups.length}
                                                    onChange={handleSelectAllGroups}
                                                />
                                            </Th>
                                            <Th>{t("Name")}</Th>
                                            <Th>{t("View")}</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {groups.map(group => (
                                            <Tr key={group.id}>
                                                <Td>
                                                    <Checkbox
                                                        isChecked={selectedGroups.includes(group.id)}
                                                        onChange={() => handleSelectGroup(group.id)}
                                                    />
                                                </Td>
                                                <Td>{group.group_name}</Td>
                                                <Td>
                                                    <IconButton onClick={() => handleViewGroup(group.id)} icon={<ViewIcon />} />
                                                </Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </>
                        )}
                    </Box>

                    <Box borderWidth="2px" borderStyle="solid" borderColor="gray.300" rounded="md" p="4" w="full">
                        <Heading as="h2" mb="4">
                            {t("waDBBlast.sendMessage")}
                        </Heading>
                        <Textarea
                            placeholder="Enter your message"
                            mb="3"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                        <Input type="file" mb="5" minH="50px" paddingTop="10px" paddingBottom="10px" onChange={handleFileChange} />
                        <Button colorScheme="green" onClick={handleSendMessages}>Send</Button>
                    </Box>
                    <Modal isOpen={showGroupModal} onClose={closeGroupModal}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>{selectedGroup?.group_name}</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <Table variant="simple" mb={4}>
                                    <Thead>
                                        <Tr>
                                            <Th>{t("name")}</Th>
                                            <Th>{t("phone")}</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {groupUsers.map((user) => (
                                            <Tr key={user.id}>
                                                <Td>{user.name}</Td>
                                                <Td>{user.phone}</Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </ModalBody>
                            <ModalFooter>
                                <Button colorScheme="red" mr={3} onClick={closeGroupModal}>
                                    {t("close")}
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </Flex>
            </Box>
        </>
    );
}

export default DashboardBlast;