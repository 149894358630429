import React, { useState, useEffect, useContext } from "react";
import {
    Box,
    Heading,
    Flex,
    useToast,
    Button,
    Spinner,
    Text,
} from "@chakra-ui/react";
import AvatarMenu from "../components/navbar/avatar-menu";
import SidebarContent from "../components/dashboard/sidebar-content";
import axios from "axios";
import SearchContext from "../SearchContext";
import { useTranslation } from "react-i18next";
import Navbar from "../components/navbar/Navbar";
import { showToast } from "../components/toast-alert";
import { useNavigate } from 'react-router-dom';

function DashboardSetting() {
    const { t } = useTranslation();
    const toast = useToast();
    const { searchResults, setSearchResults } = useContext(SearchContext);
    const [loading, setLoading] = useState(false);
    const [qrGenerated, setQrGenerated] = useState(false);
    const [qrPath, setQrPath] = useState("");
    const navigate = useNavigate();
    const setLastType = (type) => {
        localStorage.setItem("lastType", type);
    };

    useEffect(() => {
        const storedId = localStorage.getItem("id");
        if (!storedId) {
            navigate('/login');
        } else {
            setLastType("Setting")
        }
    }, []);

    const handleData = (type) => {
        setLoading(true);
        setLoading(false);
        setLoading(true);
        if (type === "Users") {
            setLoading(false);
            setLastType("Users")
        } else if (type === "Contacts") {
            setLoading(false);
            setLastType("Contacts")
        } else if (type === "Groups") {
            setLoading(false);
            setLastType("Groups")
        } else if (type === "Reports") {
            setLoading(false);
            setLastType("Reports")
        } else if (type === "EReports") {
            setLoading(false);
            setLastType("EReports")
        } else if (type === "Blast") {
            setLoading(false);
            setLastType("Blast")
            navigate('/dbblast');
        } else if (type === "Gmail") {
            setLoading(false);
            setLastType("Gmail")
            navigate('/dbgblast');
        } else if (type === "Dashboard") {
            setLoading(false);
            setLastType("Dashboard")
            navigate('/dbdashboard');
        }
    };

    const deleteSession = async () => {
        const sessionId = localStorage.getItem("id") || "mysession";
        if (!sessionId) {
            toast({
                title: "Error",
                description: "Session ID tidak ditemukan",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            return;
        }

        try {
            setLoading(true);
            const response = await axios.delete(`https://blastengine.awandigital.id/delete-session?session=${sessionId}`);
            if (response.status === 200) {
                setQrGenerated(false);
                setQrPath("");
                toast({
                    title: "Success",
                    description: "Session berhasil dihapus",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                });
            } else {
                throw new Error("Failed to delete session");
            }
            setLoading(false);
        } catch (error) {
            console.error("Failed to delete session:", error);
            setLoading(false);
            toast({
                title: "Error",
                description: `Failed to delete session: ${error.message}`,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    return (
        <>
            <Navbar
                sidebarContent={<SidebarContent handleData={handleData} />}
                buttons={
                    <>
                        <Heading>Pengaturan</Heading>
                        <AvatarMenu />
                    </>
                }
            />

            <Box
                as="section"
                minH="100vh"
                paddingLeft="20px"
                paddingRight="20px"
                ml={{
                    base: 0,
                    md: 60,
                }}
                transition=".3s ease"
            >
                <Flex align="center" justify="center" direction="column">

                    <Box borderWidth="2px" borderStyle="solid" borderColor="gray.300" rounded="md" p="4" w="full">
                        <Heading as="h2" mb="4">
                            Pengaturan
                        </Heading>
                        <Flex direction="column" gap="4">
                            <Button colorScheme="red" onClick={deleteSession} isLoading={loading}>
                                Hapus Session WhatsApp
                            </Button>
                        </Flex>
                    </Box>
                </Flex>
            </Box>
        </>
    );
}

export default DashboardSetting;